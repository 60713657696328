import React from 'react'
import Layout from '../../components/layout/Layout'
import { SeriesPage } from '../../components/series/SeriesPage'

const FiveMinutesVimSeries = () => {
  const name = '5-minutes-vim'
  const title = '5 Minutes Vim'
  const subtitle = 'Learn Useful Vim Tricks in Under 5 Minutes'
  const image = '/images/5-minutes-vim.jpg'
  const imageAlt = 'A text that reads: 5 minutes Vim'

  return (
    <Layout>
      <SeriesPage
        name={name}
        title={title}
        subtitle={subtitle}
        image={image}
        imageAlt={imageAlt}
      >
        <p>
          A great way to improve your Vim skills. Useful Vim tips and tricks in
          under 5 minutes.
        </p>
        <p>
          Vim is this wonderful editor that is different from anything else out
          there and which has persevered over the decades increasing in usage
          and gathering a crowd of passionate fans in love with Vim and its
          awesome capabilities.
        </p>
        <p>
          While Vim is extremely powerful it has a humongous amount of features
          that make mastering Vim quite the daunting experience. This series
          aims to teach you something new about Vim in just under 5 minutes. And
          so expand and improve your Vim knowledge one small step at a time.
        </p>
      </SeriesPage>
    </Layout>
  )
}

export default FiveMinutesVimSeries
